.profileWhole{
    gap: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
}

.profileLeft{
    min-height: 516px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    padding: 20px 24px 36px 24px;
}
.profileRight{
    height: 516px;
    /*padding-top: 100px;*/
    /*padding-bottom: 100px;*/
    width: 50%;
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    padding: 148px 24px 136px 24px;
}

.profileInfo{
    font-family: Inter_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    color: #959595;
    margin-right: 8px;
    margin-bottom: 24px;
}
.profileInfoWritten{
    font-family: Inter_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color:#393122
}

.imgUploaderDiv{
    border-radius: 8px;
    border: 1px dashed #9F8F5B;
    max-width: 405px;
    width: 100%;
    height: 200px;
    margin:0 auto 48px;
    display: flex;
    justify-content: center;
    align-items: center
}
.changePass{
    padding-top: 16px;
    border-top: 1px solid #D4D4D4;
    font-family: Inter_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    color: #9F8F5B;
    cursor: pointer;

}
.changePassEdit{
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid #D4D4D4;
    border-bottom: 1px solid #D4D4D4;
    margin-bottom: 24px;
    font-family: Inter_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    color: #9F8F5B;
    cursor: pointer;
}

.editIconDiv{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.avatarDiv{
    height: 129px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.emailInput{
    width: 100%;
    padding: 10px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #393122;
    border:  1px solid #D4D4D4;
    border-radius: 4px;
    margin-bottom: 24px;
    background-color: #D4D4D4;
}

.emailInput :focus {
    outline: none;
}
