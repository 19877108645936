.container {
    width: 160px;
    height: 160px;
    border: 2px dashed rgba(159, 143, 91, 1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px 0;
    padding: 16px;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.container > input{
    display: none;
}
.container > span {
    text-align: center;
}
.file {
    width: 200px;
    height: 50px;
}
.container:hover {
    border: 3px dashed rgba(159, 143, 91, 1) !important;
}
