.dropzone {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.belowPart{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.belowPartUploaded{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textDragDrop{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #393122;
    text-align: center;
    max-width: 350px;
    width: 100%;
    margin:0 auto 16px;
}
.upladedImageDiv{
    text-align: center;
}
.uploadButton{
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #9F8F5B;
    text-decoration: underline;
    margin-left: 5px;
    background-color: transparent;
    border: none;
}


.imageStyle{
    object-fit: cover;
}

.max{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #393122;
    text-align: center;
}

.iconDiv{
    padding-top: 16px;
    padding-right: 16px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.rejectedClass{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #CB2D25 !important;
    text-align: center;
}