.dragDropsEdit{
    display: flex;
    align-self: center;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 48px;
    max-width: 1760px;
    width: 100%;
}
.singleDragDropEdit{
    border: 1px dashed #9F8F5B;
    border-radius: 8px;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputsEdit{
    max-width: 1760px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
}

.selectSearch{
    max-width: 560px;
    width: 100%;
    padding: 10px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #393122;
    background: #FFFFFF;
    border:  1px solid #D4D4D4;
    border-radius: 4px;
}

.notActiveBtn{
    background: #D4D4D4;
    border-radius: 4px;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.activeBtn{
    background: #9F8F5B;
    border-radius: 4px;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.paymentsApprovedWhole{
    width: 100%;
    border-bottom: 1px solid #D4D4D4;
    display: flex;
    justify-content: space-between;
}

.paymentsApprovedPart{
    width: 20%;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border:none
}

.editAction{
    cursor: pointer;
}

.paymentsPart{
    padding-bottom: 50px;
}

.inputs{
    max-width: 1760px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
}

.industryContainer{
    display: flex;
    gap: 32px;
}

.singleIndustry{
    border-radius: 12px;
    background: #E9E9E9;
    max-width:187px;
    height: 187px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.deleteIconDiv{
    display: flex;
    align-self: flex-end;
    width: 32px;
    height: 32px;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
}
.singleIndustryDiv{
    margin-bottom: 48px;
    max-width:187px;
    width: 100%;
}

.industryImg{
    width: 70px;
    height: 74px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-self: center;
}
.industryName{
    font-family: Inter_Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
    text-align: center;
}
.industryName:first-letter{
    text-transform: capitalize;
}

.switcherDiv{
    display: flex;
    align-items: center;
    padding-right: 16px;
    border-right: 1px solid #D4D4D4;
    margin-right: 16px;
}

.switcherName{
    margin-right: 16px;
    font-family: Inter_Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
}

.title {
    margin: 40px 0 30px 0;
    color: rgba(41, 56, 68, 1);
}

.block {
    border: 1px solid rgba(41, 56, 68, 1);
    padding: 16px 22px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    flex-direction: column;
    gap: 16px 0;
}

.block > .list {
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 4px;
    padding: 10px;
    transition: all 120ms linear;
    cursor: pointer;
}

.block .selected_plan {
    background-color: rgba(212, 212, 212, 1);
    color: rgba(0, 0, 0, 0.6);
}

.downloadedFiles{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.downloadPhoto{
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: Inter_Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
}