.container{
    grid-template-rows: repeat(2,1fr);
    margin: 26px 0 0 0 ;
    gap:  12px 0;

}

.container,.row{
    display: grid;
}

.row{
    grid-template-columns: repeat(2,1fr);
    gap: 16px;

}

.title{
    color: rgba(41, 56, 68, 1)
}

.list_container{
    display: flex;
    gap: 0 16px;
    align-items: center;
    margin: 10px 0;
    transition: 160ms color linear;
}

