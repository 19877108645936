.bigContainer{
    padding: 40px 80px;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgba(41, 56, 68, 1);
    max-width: 840px;
    width: 100%;
    margin: 100px auto 0;
    gap: 20px 0;
}

.row{
    display: flex;
    justify-content: space-between;
    width: 100%
}

.form{
    max-width: 406px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.row > .btn{

    color: rgba(212, 212, 212, 1);
}

.title{
    font-family: Inter_SemiBold, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color:#293844;
}

.text{
    /*margin-top: 24px;*/
    margin-bottom: 24px;
    \font-family: Inter_Medium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #293844;
}