.dragDrops{
    display: flex;
    align-self: center;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 48px;
    max-width: 1760px;
    width: 100%; display: flex;
    align-self: center;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 48px;
    max-width: 1760px;
    width: 100%;
}
.singleDragDrop{
    border: 1px dashed #9F8F5B;
    border-radius: 8px;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.singleDragDropRed{
    border: 1px dashed #CB2D25;
    border-radius: 8px;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputs{
    max-width: 1760px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 32px;
}

.selectSearchError{
    max-width: 860px;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid #CB2D25 !important;
    border-radius: 4px;;
}

.notActiveBtn{
    background: #D4D4D4;
    border-radius: 4px;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.activeBtn{
    background: #9F8F5B;
    border-radius: 4px;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.paymentsApprovedWhole{
    width: 100%;
    border-bottom: 1px solid #D4D4D4;
    display: flex;
    justify-content: space-between;
}

.paymentsApprovedPart{
    width: 20%;
    font-family: Inter_Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border:none

}

.editAction{
    cursor: pointer;
}

.paymentsPart{
    padding-bottom: 50px;
}

.errorText{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #CB2D25 !important;
    margin-top: -22px;
}

.title {
    margin: 40px 0 30px 0;
    color: rgba(41, 56, 68, 1);
}

.block {
    border: 1px solid rgba(41, 56, 68, 1);
    padding: 16px 22px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    flex-direction: column;
    gap: 16px 0;
}

.block > .list {
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 4px;
    padding: 10px;
    transition: all 120ms linear;
    cursor: pointer;
}

.block .selected_plan {
    background-color: rgba(212, 212, 212, 1);
    color: rgba(0, 0, 0, 0.6);
}
