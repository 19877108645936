.loginBtn{
    max-width: 560px;
    width: 100%;
    border-radius: 4px;
    background: #9F8F5B;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -8px;
    margin-bottom: 48px;
}

.forgot{
    font-family: Inter_Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #393122;
    cursor: pointer;
}
.signIn{
    font-family: Inter_Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #393122;
}
.signInSpan{
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}