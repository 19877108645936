.newPasswordBtn{
    max-width: 560px;
    width: 100%;
    border-radius: 4px;
    background: #9F8F5B;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
}