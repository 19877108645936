.modalInside{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.closingModal{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #D4D4D4;
    margin-bottom: 48px;
}

.modalTitle{
    font-family: Inter_Medium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
}

.closeModalImg{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.downloadIconStyle{
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 12px;
}

.imageContainer{
    max-width: 576px;
    width: 100%;
    height: 340px;
    /*object-fit: contain;*/
}
.imageStyle{
    max-width: 576px;
    width: 100%;
    height: 340px;
    object-fit: contain;
}
