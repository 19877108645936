.barStyling{
    max-width: 500px;
    width: 100%;
    margin-bottom: 50px;
}

.barTitle{
    font-family: Inter_Medium, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
}