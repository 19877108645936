.firstButton{
    margin-top: 24px;
    margin-bottom: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 560px;
    width: 100%;
    border-radius: 4px;
    background: #9F8F5B;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.secondBtn{
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #9F8F5B;
    max-width: 560px;
    width: 100%;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393122;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backToSign {
    margin-top: 16px;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393122;
    cursor: pointer;
}
.sendText{
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393122;
    margin-bottom: 16px;
    text-align: center;
}