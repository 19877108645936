.headerWhole{
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 100000;
}
.logoDiv{
    cursor: pointer;
    width: 20%;
}

.headerRight{
    display: flex;
    align-items: center;
}
.leftBtn{
    padding: 12px 32px;
    border-radius: 4px;
    background: #9F8F5B;
    font-family: Inter_Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 8px;
}
.rightBtn{
    padding: 12px 32px;
    border-radius: 4px;
    background:  #FFF;
    font-family: Inter_Medium, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9F8F5B;
    cursor: pointer;
}
.headerMiddle{
    justify-content: flex-start;
    gap: 12px;
    display: flex;
    width: 60%;
}

@media only screen and (min-width:769px) and (max-width: 1024px) {
    .leftBtn{
        padding:8px 8px;
        font-size: 16px;
        margin-right: 8px;
        white-space: nowrap;
    }
    .rightBtn{
        padding:8px 8px;
        font-size: 16px;
        margin-right: 8px;
        white-space: nowrap;
    }
    .headerMiddle{
       margin-left: 10px;
    }
}

@media only screen and (min-width:320px) and (max-width: 768px) {
    .headerWhole{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    .leftBtn{
        padding:6px 8px;
        font-size: 14px;
        margin-right: 8px;
    }
    .rightBtn{
        padding:6px 8px;
        font-size: 14px;
        margin-right: 8px;
    }
    .headerRight{
        margin-top: 10px;
    }
    .headerMiddle{
        gap: 6px;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 6px;
    }
}

