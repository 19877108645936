.footerWhole{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logoDiv{
    cursor: pointer;
}
.footerMiddle{
    font-family: Inter_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}
.footerRight{
    display: flex;
}
.footerIcon{
    margin-right:16px;
    cursor: pointer;
}

@media only screen and (min-width:769px) and (max-width: 1023px) {
    .footerMiddle{
        font-size: 16px;
        text-align: center;
    }
    .footerIcon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

@media only screen and (min-width:425px) and (max-width: 768px) {
    .footerWhole{
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .footerMiddle{
        text-align: center;
        margin-bottom: 10px;
    }
    .footerIcon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

@media only screen and (min-width:320px) and (max-width: 425px) {
    .footerWhole{
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .footerMiddle{
        text-align: center;
        margin-bottom: 10px;
    }
    .footerIcon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

