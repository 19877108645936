.headerWhole{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 100000;
}
.headerMiddle{
    gap: 12px;
    display: flex;
}
.logoDiv{
    cursor: pointer;
}

.headerMiddleInside{
    cursor: pointer;
    font-family: Inter_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 10px;
    text-decoration: none;
}

.activeHeader{
    border-bottom: 1px solid #9F8F5B;
    cursor: pointer;
    font-family: Inter_Medium,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 10px;
    text-decoration: none;
}
.myProfileHeader{
    font-family: Inter_Regular,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-left: 16px;
    margin-right: 12px;
    cursor: pointer;
}

.downArrowImg{
    margin-left: 12px;
}

.headerRight{
    display: flex;
    align-items: center;
}

.sidebarUl{
    display: flex;
}
.avatarStyle{
    width: 50px;
    height: 50px;
    border-radius: 360px;
}

.avatarImg{
    width: 50px;
    height: 50px;
    border-radius: 360px;
}


@media only screen and (min-width:769px) and (max-width: 1023px) {

}

@media only screen and (min-width:320px) and (max-width: 768px) {
    .activeHeader{
        font-size: 14px;
        padding-left:10px;
        padding-right: 10px;
        padding-bottom: 10px;
        text-decoration: none;
        white-space: nowrap;
    }
    .headerMiddleInside{
        font-size: 14px;
        padding-left:10px;
        padding-right: 10px;
        padding-bottom: 10px;
        text-decoration: none;
        white-space: nowrap;
    }
}

