.pieStyle{
    /*max-width: 400px;*/
    width: 100%;
    height:400px;
    margin-bottom: 100px;
}

.pieTitle{
    font-family: Inter_Medium, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
}