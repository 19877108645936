
hr{
    color: #E9E9E9;
}

.principal_container{
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: center;
    margin-right: auto;
    margin-top: 80px;

}

.secondary_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    gap: 60px;
    width: 100%;
    margin-bottom: 60px;
}

.downDiv{
    height: 75px;
    max-width: 169px;
    width: 100%;
    margin-top: 15px;
}
.titleIndicators{
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 80px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: #393122;
    display: flex;
}
.summary_total {
    display: flex;
    margin-top: 64px;
}

.iconStyle{
    width: 100%;
    /*height: 100%;*/
    min-height:58px;
}


.quantity{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    /*justify-content:flex-end;*/
    /*width: 20%;*/
    /*margin: auto;*/
    height: 300px;
    border-radius: 8px;
    border: 1px dashed #D4D4D4;
    min-width: 478px;
    width: 30%;
    /* line-height: 200px; */
}

.number{
    font-family: Inter_Bold, sans-serif;
    font-size: 50px;
    font-weight: 500;
    color: #9F8F5B;
}

.name_item{
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: #393122;
}

.chart{
    display: flex;
    width: 68%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}

.items{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: #393122;
    line-height: 24.2px;
    width:40%;
    overflow-y: auto;
    height: 500px;
}

.category {
    justify-content: flex-start;
    margin-bottom: 16px;
}


.elements{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    width: 500px;

}

.completed{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: black;
}

.titleIndicator{
    color: #393122;
    text-align: center;
    font-family: Inter_Medium, sans-serif;
    margin-bottom: 5px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /*white-space: nowrap;*/
    max-width: 187px;
}
.brownTitileIndicator {
    color: #9F8F5B;
    text-align: center;
    font-family: Inter_Medium, sans-serif;
    margin-bottom: 5px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /*white-space: nowrap;*/
    max-width: 187px;
}

.brownSubtitileIndicator{
    color: #9F8F5B;
    text-align: center;
    font-family: Inter_Medium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.subtitleIndicator{
    color: #393122;
    text-align: center;
    font-family: Inter_Medium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.iconsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
}
/*.iconStyle{*/
/*    width: 100%;*/
/*}*/

/*.iconDiv{*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.detailDiv{
    margin-top: 10px;
    z-index: 1000;
    padding: 10px 10px;
    font-family: Inter_Medium, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: rgba(37, 35, 30, 0.60);
    border: 1px solid rgba(159, 143, 91, 0.60);
}

.downPart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px
}

.proposalsPart{
    width: 60%;
}
.proposalsTitle{
    font-family: Inter_Medium, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #393122;
    padding-bottom: 32px;
    border-bottom: 1px solid #D4D4D4;
    margin-bottom: 32px;
}
.proposalsDetails{
    padding: 32px;
    border-radius: 12px;
    border: 1px solid #D4D4D4
}

.chartsPart{
    width: 40%;
    margin: 80px auto 0;
}

.proposalSubtitle{
    font-family: Inter_Medium, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #393122;
    margin-bottom: 16px;
}

.proposalText{
    font-family: Inter_Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #393122;
}

.indicatorImages{
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}

.indImg{
    width: 76px;
    height: 80px;
    margin-right: 20px;
    cursor: pointer;
}
.imgTitle{
    margin-right: 20px;
    font-family: Inter_Medium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color:#393122
}
/*.indImg:last-child{*/
/*    margin-left: 50px;*/
/*}*/