.btnStyle{
    background: #9F8F5B;
    border-radius: 4px;
    padding: 12px 32px;
    border: none;
    cursor: pointer;
    font-family: Inter_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnStyle:disabled{
    cursor: not-allowed;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

/*@media only screen and (min-width:850px) and (max-width: 1024px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width: 400px !important;*/
/*    }*/
/*}*/

/*@media only screen and (min-width:768px) and (max-width: 850px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width:300px !important;*/
/*    }*/
/*}*/
